import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Titles from '../components/Titles';
import Typer from '../components/Typer';
import TechItem from '../components/TechItem';
import InfoItem from '../components/InfoItem';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `indexpage`;

const BANNERCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__bannercontainer`;
const ITEMSCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__itemscontainer`;
//const ITEMSCONTAINERWRAP_CLASSNAME: string = `${ITEMSCONTAINER_CLASSNAME}__itemscontainerwrap`;

const HOWCANIHELPCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__howcanihelpcontainer`;
const HOWCANIHELPHEADER_CLASSNAME: string = `${HOWCANIHELPCONTAINER_CLASSNAME}__header`;

const IndexPage = (): JSX.Element => {
  return (
    <div className={DEFAULT_CLASSNAME}>
        <div className={BANNERCONTAINER_CLASSNAME}>
            <Titles />
            <Typer />
        </div>
        {/* <div className={ITEMSCONTAINER_CLASSNAME}> */}
            {/* <div className={ITEMSCONTAINERWRAP_CLASSNAME}>
                <TechItem 
                    image={<StaticImage src={'../images/webdesignsmall.png'} alt={site.indexPage.techitemOneHeader}/>} 
                    header={site.indexPage.techitemOneHeader} 
                    text={site.indexPage.techitemOneText} 
                />
                <TechItem 
                    image={<StaticImage src={'../images/apismall.png'} alt={site.indexPage.techitemThreeHeader}/>} 
                    header={site.indexPage.techitemThreeHeader} 
                    text={site.indexPage.techitemThreeText} 
                />
                <TechItem 
                    image={<StaticImage src={'../images/cloudsmall.png'} alt={site.indexPage.techitemFourHeader}/>} 
                    header={site.indexPage.techitemFourHeader} 
                    text={site.indexPage.techitemFourText} 
                />
            </div> */}
         {/* </div> */}
        <div className={HOWCANIHELPCONTAINER_CLASSNAME}>
            <div className={HOWCANIHELPHEADER_CLASSNAME}>How Can I Help?</div>
            
            <InfoItem 
                header={site.indexPage.infoitemOneHeader}
                textone={site.indexPage.infoitemOneTextOne}
                texttwo={site.indexPage.infoitemOneTextTwo}
            />
              <InfoItem 
                header={site.indexPage.infoitemTwoHeader}
                textone={site.indexPage.infoitemTwoText}
            />
        </div>
    </div>
  );
}

export default IndexPage
