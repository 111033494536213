import React from 'react';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `titlescontainer`;
const TITLEONE_CLASSNAME: string = `${DEFAULT_CLASSNAME}__titleone`;
const TITLETWO_CLASSNAME: string = `${DEFAULT_CLASSNAME}__titletwo`;

const Titles = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={TITLEONE_CLASSNAME}>{site.indexPage.titlesOne}</div>
            <div className={TITLETWO_CLASSNAME}>{site.indexPage.titlesTwo}</div>
        </div>
    );
}

export default Titles;