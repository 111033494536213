import React from 'react';

interface IInfoItemProps {
    header: string;
    textone: string;
    texttwo?: string;
}

const DEFAULT_CLASSNAME: string = `infoitem`;
const INFOITEMCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__infoitemcontainer`;
const HEADER_CLASSNAME: string = `${INFOITEMCONTAINER_CLASSNAME}__header`;
const TEXT_CLASSNAME: string = `${INFOITEMCONTAINER_CLASSNAME}__text`;

const InfoItem = (props: IInfoItemProps): JSX.Element => {
    const { 
        header, 
        textone, 
        texttwo 
    } = props;

    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={INFOITEMCONTAINER_CLASSNAME}>
                <div className={HEADER_CLASSNAME}>{header}</div>
                <div className={TEXT_CLASSNAME}>
                    {textone}
                </div>
                {texttwo ? <div className={TEXT_CLASSNAME}>{texttwo}</div> : null}
            </div>
        </div>
    );
}

export default InfoItem;