import React from 'react';
import Typist from 'react-typist';

import site from '../data/site.json';

// import { IItemProps } from './Item' 

// const Developer: IItemProps = {
//     name: 'Russ Dando',
//     age: 24,
//     location: 'Weston-Super-Mare, UK',
//     hireable: false,
// }

// const Developer:

// console.log(Developer);

const DEFAULT_CLASSNAME: string = `typercontainer`;
const ALPHA_CLASSNAME: string = `${DEFAULT_CLASSNAME}__alpha`;
const TYPER_CLASSNAME: string = `${ALPHA_CLASSNAME}__typer`;
const INDENT_CLASSNAME: string = `${TYPER_CLASSNAME}__indent`;

const ACCESS_CLASSNAME: string = `code__access`;
const PROPERTY_CLASSNAME: string = `code__property`;
const CONST_CLASSNAME: string = `code__const`;
const OPERATOR_CLASSNAME: string = `code__operator`;
const TYPE_CLASSNAME: string = `code__type`;
const CURLY_CLASSNAME: string = `code__curly`;
const STRING_CLASSNAME: string = `code__string`;
const NUMBER_CLASSNAME: string = `code__number`;
const IMPORT_CLASSNAME: string = `code__import`;

const Typer = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={ALPHA_CLASSNAME}>
                <Typist className={TYPER_CLASSNAME} startDelay={600} avgTypingDelay={30} cursor={{show: false}}>
                    <div>
                        <span className={IMPORT_CLASSNAME}>import{' '}</span>
                        <span className={CURLY_CLASSNAME}>{'{'}</span>
                        <span className={PROPERTY_CLASSNAME}>{' '}IDev{' '}</span>
                        <span className={CURLY_CLASSNAME}>{'}'}</span>
                        <span className={IMPORT_CLASSNAME}>{' '}from{' '}</span>
                        <span className={STRING_CLASSNAME}>'./Dev'</span>
                        <span className={OPERATOR_CLASSNAME}>;</span>
                    </div>

                    <div style={{marginTop: '21px'}}>
                        <span className={ACCESS_CLASSNAME}>const{' '}</span>
                        <span className={CONST_CLASSNAME}>developer</span>
                        <span className={OPERATOR_CLASSNAME}>:{' '}</span>
                        <span className={TYPE_CLASSNAME}>IDev</span>
                        <span className={OPERATOR_CLASSNAME}>{' '}={' '}</span>
                        <span className={CURLY_CLASSNAME}>{'{'}</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>name:{' '}</span>
                        <span className={STRING_CLASSNAME}>'{site.general.name}'</span>
                        <span className={OPERATOR_CLASSNAME}>,</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>age:{' '}</span>
                        <span className={NUMBER_CLASSNAME}>{site.general.age.toString()}</span>
                        <span className={OPERATOR_CLASSNAME}>,</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>gender:{' '}</span>
                        <span className={STRING_CLASSNAME}>'{site.general.gender}'</span>
                        <span className={OPERATOR_CLASSNAME}>,</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>location:{' '}</span>
                        <span className={STRING_CLASSNAME}>'{site.general.location}'</span>
                        <span className={OPERATOR_CLASSNAME}>,</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>nationality:{' '}</span>
                        <span className={STRING_CLASSNAME}>'{site.general.nationality}'</span>
                        <span className={OPERATOR_CLASSNAME}>,</span>
                    </div>

                    <div className={INDENT_CLASSNAME}>
                        <span className={PROPERTY_CLASSNAME}>hireable:{' '}</span>
                        <span className={ACCESS_CLASSNAME}>{site.general.hireable ? 'true' : 'false'}</span>
                    </div>

                    <div>
                        <span className={CURLY_CLASSNAME}>{'}'}</span>
                        <span className={OPERATOR_CLASSNAME}>{';'}</span>
                    </div>
                </Typist>
            </div>
        </div>
    );
}

export default Typer;